<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Calendar -->
      <div class="col-sm-12">
        <b-card>
          <b-card-title class="mb-1"> Legend</b-card-title>
          <b-card-body>
            <b-badge variant="primary" class="mr-1">Completed</b-badge>
            <b-badge variant="info" class="mr-1">Not Started</b-badge>
            <b-badge variant="secondary" class="mr-1">Started</b-badge>
            <b-badge variant="danger" class="mr-1">Cancelled</b-badge>
            <b-badge variant="warning" class="mr-1">Blocked</b-badge>
          </b-card-body>
        </b-card>
      </div>
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar :plugins="calendarPlugins" :events="fcEvents" :options="calendarOptions" />
          </div>
        </div>
      </div>
      <b-sidebar id="sidebar-right" bg-variant="white" right title="Update Job Card Status" backdrop shadow
        :visible="showSidebar" ref="sidebar">
        <Sidebar :data="selectedItem" @status-new="setNewStatus" />
      </b-sidebar>
      <!-- Sidebar Overlay -->
    </div>
  </div>
</template>

<script>
import { BButton, BSidebar, BCard, BCardTitle, BCardBody, BBadge } from "bootstrap-vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Sidebar from "./SidebarView.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    FullCalendar,
    BButton,
    BSidebar,
    Sidebar,
    BCard,
    BCardTitle,
    BCardBody,
    BBadge

  },

  data() {
    return {
      newstatus: false,
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin],
        initialView: "dayGridMonth",
        events: [{}],
        editable: true,
        selectable: true,
        eventClick: this.handleEventClick,
      },
      showSidebar: false,
      selectedItem: [],
    };
  },

  computed: {
    ...mapGetters("jobcardModule", {
      jobcardList: "jobcards",
      loading: "loading",
    }),
  },

  methods: {
    ...mapActions("jobcardModule", ["getJobcardListAction"]),

    setNewStatus(status) {
      this.newstatus = status;

      if (this.newstatus) {
        window.location.reload()
      }

    },
    handleEventClick(clickInfo) {
      this.showSidebar = !this.showSidebar;
      this.selectedItem = clickInfo.event;
    },
  },

  async mounted() {
    await this.getJobcardListAction()
      .then(() => {
        this.calendarOptions.events = this.jobcardList.map((event) => ({
          id: event.id,
          title: event.name,
          start: event.startDate,
          end: event.endDate,
          status: event.status,
          textColor: "#fff",
          backgroundColor:
            event.status === 0 ? "#4A7CBD" :
              event.status === 1 ? "#82868B" :
                event.status === 2 ? "#9FC54C" :
                  event.status === 3 ? "#BD3736" :
                    event.status === 4 ? "#DD8B34" :
                      "#000000",
          borderColor:
            event.status === 0 ? "#4A7CBD" :
              event.status === 1 ? "#82868B" :
                event.status === 2 ? "#9FC54C" :
                  event.status === 3 ? "#BD3736" :
                    event.status === 4 ? "#DD8B34" :
                      "#000000",
          color:
            event.status === 0 ? "#4A7CBD" :
              event.status === 1 ? "#82868B" :
                event.status === 2 ? "#9FC54C" :
                  event.status === 3 ? "#BD3736" :
                    event.status === 4 ? "#DD8B34" :
                      "#000000",
        }));
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
